.container {
  padding: 100px 40px 40px 40px;
}

.content {
  height: 50vh;
  overflow: auto;
}

.createBtn {
  margin-bottom: 20px;
}

.price {
  display: inline-block;
  font-size: 0.875rem;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  -webkit-padding-start: 0.2em;
  padding-inline-start: 0.2em;
  -webkit-padding-end: 0.2em;
  padding-inline-end: 0.2em;
  border-radius: 0.125rem;
  background: #fed7d7;
  color: #822727;
}

.bottom {
  position: fixed;
  width: calc(100% - 80px);
  bottom: 80px;
  left: 40px;
  display: flex; /* Flex 布局 */
  flex-direction: column; /* 按列排列按钮 */
  gap: 10px; /* 按钮之间间距 */
}

.bottom .summaryBtn {
  width: 100%;
  padding: 10px;
  background-color: #f0f2f5; /* 自定义背景色 */
  border: 1px solid #d9d9d9; /* 自定义边框 */
  border-radius: 4px;
  color: #000;
  font-size: 16px;
  text-align: center;
}

.submitBtn {
  /* 特殊样式定义（如有需要） */
}

.summaryBtn {
  /* 特殊样式定义（如有需要） */
}

.priceWrapper {
  position: absolute;
  right: 20px;
  top: -65px;
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 70px;
  }
}
