.submitBtn {
  align-self: flex-end;
}
.high {
  background-color: red;
}
.medium {
  background-color: yellow;
}
.low {
  background-color: green;
}
.default {
  background-color: white;
}
