.footer {
  position: fixed; /* 固定在底部 */
  bottom: 0; /* 定位到页面底部 */
  left: 0; /* 从左侧开始 */
  width: 100%; /* 占满整个宽度 */
  background-color: #001529; /* 背景颜色 */
  color: #fff; /* 字体颜色 */
  z-index: 1000; /* 确保在其他内容之上 */
  display: flex; /* 使用 flex 布局 */
  justify-content: center; /* 居中对齐 */
  align-items: center; /* 垂直居中对齐 */
  height: 60px; /* 固定高度 */
}

.footerNav {
  display: flex; /* 使用 flex 布局 */
  gap: 20px; /* 导航项之间的间距 */
}

.footerNav > div {
  position: relative;
}

.footerNav a > div {
  padding: 10px 20px; /* 内边距 */
  border-radius: 8px; /* 圆角 */
  cursor: pointer; /* 鼠标变成手型 */
  transition: all 0.3s ease-in-out; /* 平滑过渡效果 */
}

.footerNav .active > div,
.footerNav a > div:hover {
  background-color: #1677ff; /* 悬停或激活时的背景色 */
  color: #ffffff; /* 悬停或激活时的字体颜色 */
}

.dropdownGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  bottom: 55px;
  left: 0;
}

.dropdownButton {
  background: #1677ff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px; /* 与导航项分开 */
  text-align: center;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
}

.dropdownButton:hover {
  background: #0056b3;
}

.footerBtn {
  position: fixed;
  bottom: 80px;
  left: 5px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);;
}

@media screen and (max-width: 768px) {
  .dropdownGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    bottom: 65px;
    left: 50%;
    width: 90%;
    background-color: #001529;
    padding: 10px;
    border-radius: 8px;
    transform: translateX(-50%);
  }
  .dropdownButton {
    background: #1677ff;
    color: #fff;
    border: none;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
  }
  .dropdownButton:hover {
    background: #0056b3;
  }

  .footerNav {
    gap: 10px;
    font-size: 14px;
  }

  .footerNav > div {
    position: static;
  }

  .footerNav a > div {
    padding: 10px 12px;
  }
}
