.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .LoginBox {
    width: 400px;
    margin: 100px auto;
  }

  .LoginBox h2 {
    font-weight: bolder;
    font-size: 18px;
    margin-bottom: 30px;
    text-align: center;
  }
}
.heng {
  height: 10px;
}
@media screen and (max-width: 768px) {
  .LoginBox {
    width: 85%;
    margin: 100px auto;
  }

  .LoginBox h2 {
    font-weight: bolder;
    font-size: 18px;
    margin-bottom: 30px;
    text-align: center;
  }
}
