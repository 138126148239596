.p4 {
  padding: 16px 32px; /* 上下改为 16px，左右保持 32px */
}

.compactGroup .p4 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.title {
  font-size: 1.55rem !important;
  font-weight: 700 !important;
  margin-bottom: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  text-transform: uppercase;
}

/* 添加元素间距控制 */
.controlItem {
  margin-bottom: 8px;
}

.controlItem:last-child {
  margin-bottom: 0;
}