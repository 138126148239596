* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

.choose-drawer .ant-drawer-content-wrapper {
  height: 100% !important;
}

.actionTopLeft {
  display: flex;
  align-items: center;
  gap: 10px;
  position: fixed;
  top: 10px;
  left: 10px;
}

.actionTopRight {
  display: flex;
  align-items: center;
  gap: 10px;
  position: fixed;
  top: 10px;
  right: 10px;
}

.actionBottomLeft {
  display: flex;
  align-items: center;
  gap: 10px;
  position: fixed;
  bottom: 10px;
  left: 10px;
}

.actionBottomRight {
  display: flex;
  align-items: center;
  gap: 10px;
  position: fixed;
  bottom: 10px;
  right: 10px;
}
