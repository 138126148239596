.btn_head {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.main_make {
  padding: 20px;
}
.main_make h1 {
  font-size: 30px;
}
.main_make .item_make {
  margin-top: 20px;
}
.main_make .label,
.DrawerBody_main .label {
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 5px;
}
.DrawerBody_main {
  margin-top: 20px;
}
.DrawerBody_main .over {
  margin-top: 20px;
  height: 70svh;
  overflow: auto;
}
#CanvasSave {
  transition: all 0.5s;
}
#chakra-modal-ZuoFull {
  height: 100svh !important;
}

.date {
  margin-top: 20px;
  background-color: #eef2f7;
  padding: 6px 10px;
  border-radius: 6px;
  width: 100%;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}
