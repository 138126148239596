.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.headerRight {
}

.headerBtn {
  position: fixed;
  top: 100px;
  left: 5px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);;
}

@media screen and (min-width: 768px) {
  .header {
    margin: 0;
    background-color: #001529;
    color: #fff;
    height: 80px;
  }
  .headerLeft {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 30px;
    position: relative;
  }
  .headerLeft a > div {
    padding: 12px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .headerLeft .active > div,
  .headerLeft a > div:hover {
    background-color: #1677ff;
    color: #ffffff;
    padding: 12px 20px;
  }
  .dropdownGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 100%;
    left: 0;
  }
  .dropdownButton {
    background: #1677ff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    width: max-content;
  }
  .dropdownButton:hover {
    background: #0056b3;
  }
}
@media screen and (max-width: 768px) {
  .header {
    margin: 0;
    padding: 0 10px;
    background-color: #001529;
    color: #fff;
    height: 50px;
  }
  .headerLeft {
    float: left;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 12px;
    white-space: nowrap;
    position: relative;
  }
  .headerLeft a > div {
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .headerLeft .active > div,
  .headerLeft a > div:hover {
    background-color: #1677ff;
    color: #ffffff;
    padding: 10px;
  }
  .dropdownGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background-color: #001529;
    padding: 10px;
    border-radius: 8px;
  }
  .dropdownButton {
    background: #1677ff;
    color: #fff;
    border: none;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
  }
  .dropdownButton:hover {
    background: #0056b3;
  }
  .headerBtn {
    top: 60px;
  }
}
