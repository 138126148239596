.alignSelfEnd {
  align-self: flex-end;
}

.hStack {
  display: flex;
  align-items: center;
  max-width: 320px;
  gap: 5px;
}
