.container {
  margin: 32px;
  color: #ffffff;
  justify-content: space-between;
  background-color: #6b46c1;
  padding: 32px;
  height: 200px;
  border-radius: 0.75rem;
  box-shadow:
    0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  margin-bottom: 32px;
}

.text {
  color: #ffffff;
}

.title {
  font-size: 1.55rem !important;
  font-weight: 700 !important;
  margin-bottom: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  text-transform: uppercase;
}